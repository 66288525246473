import { WhatsAppOutlined } from "@ant-design/icons";
import { Button, Descriptions, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WhatsappShareButton } from "react-share";
import { setApiLink } from "../../../../store/slice/globleSlice";
import { getApplicationLink } from "../../../../components/applicationLink";
import { CopyButton } from "../style";

const CreateUserModal = ({
  newUserDetails,
  isModalOpen,
  setIsModalOpen,
  type,
}) => {
  // state
  const [copy, setCopy] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getApplicationLink().then((data) => {
      dispatch(setApiLink(data));
    });
  }, []);

  //redux
  const apiLink = useSelector((state) => state?.globleSlice?.apiLink);

  //variable
  const shareUrlString = `UserId: ${newUserDetails.userId},\n\nUserName: ${newUserDetails.accountName},\n\nAccountType: ${newUserDetails.accountType},\n\nPassward: ${newUserDetails.passward},\n\nAndroid Link: ${apiLink?.android},\n\nApple Link: ${apiLink?.apple},\n\nWebLink: https://rupot.in/login`;
  const title = "User created successful,\n\n";

  // function
  const handleOk = () => {
    setIsModalOpen(false);
    if (type === "rent") {
      navigate(`/users/accounts?type=rent`);
    } else {
      navigate(`/users/accounts`);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    if (type === "rent") {
      navigate(`/users/accounts?type=rent`);
    } else {
      navigate(`/users/accounts`);
    }
  };
  const handleCopy = async () => {
    try {
      await navigator.clipboard?.writeText(`${title}${shareUrlString}`);
      setCopy(true);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  // data
  const items = [
    {
      key: "1",
      label: "User ID",
      children: `${newUserDetails?.userId}`,
    },
    {
      key: "2",
      label: "Username",
      children: `${newUserDetails?.accountName}`,
    },
    {
      key: "3",
      label: "User Passward",
      children: `${newUserDetails?.passward}`,
    },
    {
      key: "4",
      label: "Account Type",
      children: `${newUserDetails?.accountType}`,
    },
    {
      key: "5",
      label: "Website Link",
      children: (
        <a
          href="https://rupot.in/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          rupot.in
        </a>
      ),
    },
    {
      key: "6",
      label: "Android Link",
      children: (
        <a href={apiLink?.android} target="_blank" rel="noopener noreferrer">
          rupot.in
        </a>
      ),
    },
    {
      key: "7",
      label: "Apple Link",
      children: (
        <a href={apiLink?.apple} target="_blank" rel="noopener noreferrer">
          rupot.in
        </a>
      ),
    },
    {
      key: "8",
      label: "Share with What's up",
      children: (
        <WhatsappShareButton url={shareUrlString} title={title}>
          <WhatsAppOutlined style={{ fontSize: "1.7rem" }} />
        </WhatsappShareButton>
      ),
    },
    {
      key: "9",
      label: "Copy Details",
      children: (
        <CopyButton onClick={handleCopy}>
          {copy ? "Copied!" : "Copy"}
        </CopyButton>
      ),
    },
  ];

  return (
    <Modal
      title="Created User Info"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
    >
      <Descriptions items={items} style={{ marginTop: "2rem" }} />
    </Modal>
  );
};

export default React.memo(CreateUserModal);
